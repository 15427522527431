@fontface {
  font-family: 'Bungee Inline', cursive;
  src: url('https://fonts.google.com/download?family=Roboto')
}

#root {
  max-width: 100vw;
}


body {
  font-family: 'Roboto', sans-serif;
  color: rgb(46,71,93);
  font-size: 16px;
}

nav {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

nav > ul > li {
  display: inline-block;
  padding: 0px 13px;
}

nav > ul > li > a {
  text-decoration: none;
  padding: 10px 0px;
  color: rgb(46,71,93);
}

a.active {
  border-bottom: 2px solid green;
}

.portal-header {
  z-index: 100;
  position: relative;
  background-color: #ffffff;
  color: rgb(46,71,93) !important;
  font-weight: 600;
}

.portal-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.lead-container {
  text-align: center;
  padding: 20px 40px;
}

.lead-title {
  font-size: 1.8rem;
}

.lead {
  padding: 20px;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  margin-top: 10px;
}

.full-container {
 width: 100%;
}

.brand {
  font-size: 26px;
  color: #395216 !important;
}

.brand-img {
  background: url('resources/layout/brand.png');
  width: auto;
  height: 50px;
}

.vision-mission-container {
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
}

.vision-mission-container .divider {
  width: 2px;
  background-color: #efefef;
  border-color:#efefef;
  height: 300px;
}

.vision-mission-container .block {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.vision-mission-title {
  font-size: 2.5rem;
  font-weight: 400;
}

.vision-mission {
  padding: 20px;
  font-size: 1rem;
  line-height: 1.5em;
}

.csr-headline {
  font-size: 26px;
  text-align: center;
  margin-top: 100px;
}

.csr-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.csr-icon {
  font-size: 4rem;
}

.csr-line1 {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.csr-line2 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.csr-block {
  height: 340px;
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #ECF1E1;
  color: #6b6754;
  border-radius: 320px;
  justify-content: center;
  margin: 10px;
  padding: 25px;
  box-shadow: 5px 5px rgba(107,112,45, .2);
}

.csr-block-text {
  line-height: 1.4rem;
}

.cloud{ 
  margin-top:70px;
  width:350px;
  height:120px;
  background:#ECEFF1;
  box-shadow: 10px 10px rgba(0,0,0,0.2);
  border-radius:100px;
}
.cloud::after, .cloud::before{
   content:"";
   position:relative;
   display:inline-block;
   background:inherit;
   border-radius:inherit;
}
.cloud::after{
   width:100px;
   height:100px;
   top:-220px;
   left:50px;
}
.cloud::before{
   width:180px;
   height:180px;
   top: -70px;
   left:130px;
}

.app-breadcrumb {
  font-size: .9rem;
  background-color: #222222;
  width: 100% !important;
  color: #ffffff;
  display: flex;
  height: 40px;
}

.breadcrumb {
  height: 40px;
  margin:0px;
  display: flex;
  align-items: center;
}

button a {
  text-decoration: none;
  cursor: pointer;
  color: #212529;
}

.breadcrumb a {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.breadcrumb a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.portal-footer-grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portal-footer-grid div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.portal-footer-grid div ul li {
  list-style-type: none;
  margin: 10px;
}

.portal-footer-grid div ul li a {
  text-decoration: none;
  font-size: .9rem;
  color: #e2e2e2;
}

.portal-footer {
  background-color: #222222;
  color: #e2e2e2;
  border-top: 4px solid #395216;
  font-size: .9rem;
}


.footer-sticky-bottom {
  background-color: white;
  width: 100%;
  color: #222222;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 100px;
  font-size: .8rem;
  text-align: center;
  margin-top: 0px;
}

.contact-container {
  display: flex;
  height: 500px;
}

.contact-form {
  flex: 1; 
}

.contact-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  font-size: 1.2rem;
  justify-content: flex-start;
}

.contact-details div {
  margin: 10px;
}


.header-pull-down {
  background-color: #efefef;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -20px;
  padding-top: 20px;
  padding-bottom: 40px;
  color: #6b6754;
}

/* .product-background-1 {
  background-image: url('./resources/product/product1.jpg') , url('./resources/product/product3.jpg'); 
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: top center, bottom center;
  background-color: rgb(255,255,255, 0.2);
} */